<template>
  <div>
    <div class="header">
      <el-select
        size="small"
        v-model="uploadLang"
        placeholder="Choose language to upload"
        style="float:left; width: 120px;"
      >
        <el-option
          v-for="item in langs"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-upload
        accept=".properties"
        action=""
        style="float:left; margin-left: 10px; margin-bottom: 3px;"
        :show-file-list="false"
        :on-change="(file) => handleOnChange(file, uploadLang)"
      >
        <el-button round type="primary" icon="el-icon-upload2" size="small"
          >Upload {{ dic[uploadLang] }}</el-button
        >
      </el-upload>
      <el-select
        v-model="selectedLangs"
        multiple
        size="small"
        collapse-tags
        style="margin-left: 20px; float: left;"
        placeholder="Choose Languages"
        @change="handleLangChange"
      >
        <el-option
          v-for="item in langs"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="kw"
        size="small"
        style="width: 200px; margin-left: 10px; float: left;"
        placeholder="Search..."
        clearable
        suffix-icon="el-icon-search"
      ></el-input>
      <el-checkbox
        v-model="onlyShowEmpty"
        :border="true"
        size="small"
        @change="handleEmptyChange"
        style="margin-left:10px; float: left;"
        >Empty Only</el-checkbox
      >
      <el-checkbox
        v-if="false"
        v-model="onlyShowDiffer"
        :border="true"
        size="small"
        @change="handleDifferChange"
        style="float: left; margin-left: -20px;"
        >Differ Only</el-checkbox
      >
      <el-tag
        type="success"
        effect="light"
        style="margin-left: 10px; float: left;"
        >In total: {{ selectedProperties.length }}</el-tag
      >
      <el-checkbox
        border
        v-if="true"
        v-model="onlyShowDiffer"
        style="margin-left: 20px; float:left;"
        type="primary"
        size="small"
        @change="handleCompare"
        >Only Show Differ</el-checkbox
      >
      <el-button
        style="margin-left: 10px; float:left; width: 100px;"
        type="primary"
        size="small"
        v-if="true"
        @click="handleCreate"
        >Create</el-button
      >
      <el-button
        size="small"
        v-if="false"
        round
        type="primary"
        style="margin-left: 10px; float: left;"
        >Add Language</el-button
      >
    </div>
    <el-table
      ref="tableProperty"
      size="mini"
      :data="selectedProperties"
      v-loading="loading"
      border
      fit
      :height="tableHeight"
      style="margin-top: 10px; float:left; min-width: 1100px;"
      :cell-style="
        ({ row, column, rowIndex, columnIndex }) => {
          return cellStyle(row, columnIndex);
        }
      "
    >
      <el-table-column
        prop="key"
        fixed="left"
        label="Key"
        sortable
      ></el-table-column>
      <el-table-column
        v-for="item in selectedLangs"
        :key="item"
        :prop="item"
        :label="dic[item]"
      ></el-table-column>
      <!-- <el-table-column prop="en" label="English" sortable ></el-table-column>
      <el-table-column prop="zh" label="Chinese" sortable> -->
      <!-- <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.zh"
            type="textarea"
            clearable
          ></el-input>
        </template> -->
      <!-- </el-table-column> -->
      <!-- <el-table-column prop="de" label="German" sortable> -->
      <!-- <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.de"
            type="textarea"
            clearable
          ></el-input>
        </template> -->
      <!-- </el-table-column> -->
      <el-table-column width="100px" fixed="right" label="Edit" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            plain
            @click="handleEdit(scope)"
          ></el-button>
          <el-button
            v-if="true"
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            plain
            @click="handleDelete(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogVisible">
      <dic-detail
        v-if="dialogVisible"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :editProperty="property"
      ></dic-detail>
    </el-dialog>
  </div>
</template>
<script>
import http from "../utils/http";
import DicDetail from "./DicDetail.vue";
import config from "../utils/config";
export default {
  name: "Dictionary",
  components: {
    DicDetail,
  },
  data() {
    return {
      onlyShowEmpty: false,
      properties: [],
      kw: "",
      tableHeight: window.innerHeight - 170,
      dialogVisible: false,
      title: "Create Property",
      property: {},
      loading: false,
      langs: config.langs,
      selectedLangs: ["en", "zh", "de"],
      uploadLang: "en",
      dic: {},
      onlyShowDiffer: false,
    };
  },
  computed: {
    selectedProperties() {
      console.log("computed");
      var round1 = this.properties.filter((value) => {
        var k = this.kw.toLowerCase().trim();
        var flag1 = false;
        if (this.selectedLangs.length == 0) {
          return true;
        }
        this.selectedLangs.forEach((lang) => {
          if (value.key.toLowerCase().indexOf(k) > -1) {
            flag1 = true;
            return;
          }
          if (
            value[lang] != null &&
            value[lang].toLowerCase().indexOf(k) > -1
          ) {
            flag1 = true;
            return;
          }
        });
        if (this.onlyShowEmpty == true) {
          flag1 = false;
          this.selectedLangs.forEach((lang) => {
            if (value[lang] == null || value[lang] == "") {
              flag1 = true;
              return;
            }
          });
        }
        return flag1;
        // var en = value.en == null ? "" : value.en.toLowerCase();
        // var zh = value.zh == null ? "" : value.zh.toLowerCase();
        // var de = value.de == null ? "" : value.de.toLowerCase();
        // return (
        //   value.key.toLowerCase().indexOf(k) > -1 ||
        //   en.indexOf(k) > -1 ||
        //   zh.indexOf(k) > -1 ||
        //   de.indexOf(k) > -1
        // );
      });
      if (this.onlyShowDiffer == true) {
        var round2 = round1.filter((value) => {
          return value[this.selectedLangs[0]] != value[this.selectedLangs[1]];
        });
        return round2;
      }
      return round1;
      // var round2 = round1.filter((value) => {
      //   var en = value.en == null ? "" : value.en.toLowerCase();
      //   var zh = value.zh == null ? "" : value.zh.toLowerCase();
      //   var de = value.de == null ? "" : value.de.toLowerCase();
      //   return this.onlyShowEmpty == true
      //     ? en.trim() == "" || zh.trim() == "" || de.trim() == ""
      //     : true;
      // });
      // return round2;
    },
  },
  mounted() {
    console.log("mounted");
    config.langs.forEach((lan) => {
      this.dic[lan.key] = lan.value;
    });
    this.getProperties();
    console.log(localStorage.getItem("selectedLangs"));
    if (
      localStorage.getItem("selectedLangs") != null &&
      localStorage.getItem("selectedLangs") != ""
    ) {
      this.selectedLangs = localStorage.getItem("selectedLangs").split(",");
    }
    this.$refs.tableProperty.doLayout();
  },
  methods: {
    cellStyle(row, columnIndex) {
      if (
        columnIndex > 0 &&
        this.onlyShowDiffer == true &&
        row[this.selectedLangs[0]] != row[this.selectedLangs[1]]
      )
        return "color: red";
    },
    handleDifferChange(value) {
      if (value == true && this.selectedLangs.length != 2) {
        this.$message.error("Please select 2 languages to compare!");
        this.onlyShowDiffer = false;
      }
    },
    handleCompare() {
      if (this.selectedLangs.length != 2) {
        this.$message({
          type: "error",
          message:
            "You can only compare two, please select only two to compare!",
        });
        this.onlyShowDiffer = false;
        return;
      }
    },
    handleEmptyChange(value) {
      if (value == true) {
        this.kw = "";
      }
    },
    handleDelete(scope) {
      http.post("/property/deleteById", { _id: scope.row._id }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "The property has been deleted successfully!",
          });
          this.properties.splice(this.properties.indexOf(scope.row), 1);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleCreate() {
      this.property = {};
      this.dialogVisible = true;
    },
    handleConfirm() {
      this.dialogVisible = false;
      this.getProperties();
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleEdit(scope) {
      this.title = "Edit Property";
      this.property = scope.row;
      this.dialogVisible = true;
    },
    getProperties() {
      this.loading = true;
      http.get("/property").then((res) => {
        if (res.data.status == 1) {
          this.properties.splice(0, this.properties.length);
          this.properties = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$$message.error(res.data.message);
        }
      });
    },
    handleLangChange(selectedLangs) {
      if (selectedLangs.length != 2) {
        this.onlyShowDiffer = false;
      }
      localStorage.setItem("selectedLangs", selectedLangs);
      this.$nextTick(() => {
        this.$refs.tableProperty.doLayout();
      });
    },
    handleOnChange(file, lang) {
      var _this = this;
      if (file.status !== "ready") return;
      var suffix = file.name.split(".");
      if (suffix[suffix.length - 1] != "properties") {
        this.$message.error("Only .properties is allowed to be uploaded.");
        return;
      }
      this.loading = true;
      var array = [];
      var reader = new FileReader();
      reader.onload = function() {
        if (reader.result) {
          //打印文件内容
          // console.log(reader.result);
          var lines = reader.result.split("\n");
          lines.forEach((value) => {
            var items = value.split("=");
            if (items.length == 2 && items[0].slice(0, 1) != "#") {
              var obj = {};
              obj.key = items[0];
              obj[lang] = items[1].trim();
              array.push(obj);
              // if (lang == "en") {
              //   array.push({
              //     key: items[0],
              //     en: items[1].trim(),
              //   });
              // } else if (lang == "zh") {
              //   array.push({
              //     key: items[0],
              //     zh: items[1].trim(),
              //   });
              // } else if (lang == "de") {
              //   array.push({
              //     key: items[0],
              //     de: items[1].trim(),
              //   });
              // } else if (lang == "ja") {
              //   array.push({
              //     key: items[0],
              //     ja: items[1].trim(),
              //   });
              // } else if (lang == "ko") {
              //   array.push({
              //     key: items[0],
              //     ko: items[1].trim(),
              //   });
              // }
            }
          });
          http
            .put("/property/upsert", { properties: array, lang: lang })
            .then((res) => {
              if (res.data.status == 1) {
                _this.$message({
                  type: "success",
                  message: "The file was imported successfully",
                });
                _this.getProperties();
                _this.loading = false;
              } else {
                _this.$message.error(res.data.message);
              }
            });
        }
      };
      // console.log("start to read");
      reader.readAsText(file.raw, "utf-8");
      // reader.readAsText(file.raw)
    },
  },
};
</script>
<style scoped>
.header {
  min-width: 1100px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  vertical-align: middle;
  padding: 5px 0;
  /* overflow: scroll; */
  /* margin-top: -10px; */
  display: flex;
  align-items: center;
}
</style>
